import {React, useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom';


        const Sidebar = () => {
            const navigate = useNavigate();
            const [isDisplay, setIsDisplay] = useState(false);


            useEffect(() => {

            if (!localStorage.getItem('user')) {
                // navigate('/', {user_id : });
                navigate('/login');
            }
            else
            {

                //##########################################################
                let x = JSON.parse(localStorage.getItem('user'));
                console.log("ADMIN=====:-",x.isAdmin)
                if(x.isAdmin)
                {
                    // console.log("----------isAdmin-------");
                    // getData1(x.id);
                    setIsDisplay(true);

                }
            }
            //#################
    }, [navigate]);



return (
        <div className="sidebar">
    <div className="brand-logo">
        <a className="full-logo" onClick={()=>{navigate("/");}} role="button">
            <img src="./images/SoterraProperties/soterra_properties_logo_PNG.png" alt="" width="100%" /> {/*<strong>Shiny Lobster</strong>*/}</a>
    </div>
    <div className="menu">
        <ul>
            <li>
                <a onClick={()=>{navigate("/");}} role="button">
                    <span><i className="ri-home-fill"></i></span>
                    <span className="nav-text">Home</span>
                </a>
            </li>
            <li>
                <a onClick={()=>{navigate("/dashboard");}} role="button">
                    <span><i className="ri-layout-grid-fill"></i></span>
                    <span className="nav-text">Dashboard</span>
                </a>
            </li>
            {
                isDisplay?(
                    <li className="">
                        <a onClick={()=>{navigate("/notification");}} role="button">
                            <span><i class="ri-notification-2-line"></i></span>
                            <span className="nav-text">Notification</span></a>
                    </li>
                ):(
                    <li className="">
                        <a onClick={()=>{navigate("/myactivity");}} role="button">
                            <span><i class="ri-time-line"></i></span>
                            <span className="nav-text">My Activity</span></a>
                    </li>
                   )
            }
        
            {
                isDisplay?(
                    <li className="">
                        <a className="nav-link" onClick={()=>{navigate("/upload");}} role="button">
                            <span><i class="ri-file-list-3-line"></i></span>
                            <span className="nav-text">Upload</span></a>
                    </li>
                ):(
                    <li className="">
                        <a className="nav-link" onClick={()=>{navigate("/mypurchases");}} role="button">
                            <span><i class="ri-wallet-line"></i></span>
                            <span className="nav-text">My Assets</span></a>
                    </li>
                   )
            }

        </ul>
    </div>
</div>
        )
        }

export default Sidebar;